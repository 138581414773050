<template>
  <div class="show-room-nav content">
    <div class="nav-left">
      <div class="nav-left-item back-btn" @click="goHome">
        <span class="arrows"></span>
        返回首页
      </div>
      <div
        v-for="el in tabData"
        :key="el.id"
        :class="[
          el.id == searchId
            ? 'nav-left-item select-btn'
            : 'nav-left-item other-btn',
        ]"
        @click="handleRoom(el.id)"
      >
        {{ el.name }}
      </div>
    </div>
    <div class="nav-right">
      <div class="nav-right-item">
        <div class="yes-bulk"></div>
        <div class="info">有企业入驻</div>
      </div>
      <div class="nav-right-item">
        <div class="no-bulk"></div>
        <div class="info">无企业入驻</div>
      </div>
    </div>
  </div>
</template>

<script>
import { listByParent } from "@/api/cms/booth.js";
export default {
  props: {
    searchId: {
      value: -1,
      type: Number,
    },
  },
  data() {
    return {
      tabData: [],
    };
  },
  methods: {
    goHome() {
      this.$router.push("/cms/home");
    },
    handleRoom(id) {
      this.$router.push(`/cms/room/${id}`);
      this.$parent.search(id);
    },
    listByParent() {
      listByParent(0).then((res) => {
        for (var i = 0; i < res.length; i++) {
          var item = res[i];
          let jsonConfig = JSON.parse(item.jsonConfig);
          item.info = jsonConfig;
        }
        this.tabData = res;
      });
    },
  },
  created() {
    this.listByParent();
  },
};
</script>

<style scoped>
.show-room-nav {
  /* margin-top: 120px; */
  background: #f5f5f5;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}
.nav-left {
  display: flex;
}
.nav-left .nav-left-item {
  border: 1px solid #010117;
  border-radius: 60px;
  padding: 8px 10px;
  margin-right: 20px;
}
.nav-left .nav-left-item:hover {
  cursor: pointer;
}
.back-btn .arrows {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent; /* 设置上边框为透明 */
  border-bottom: 5px solid transparent; /* 设置下边框为透明 */
  border-right: 10px solid #010117; /* 设置右边框为实心黑色 */
}
.nav-left .other-btn {
  background-color: #010117;
  color: #fff;
}
.nav-left .select-btn {
  background-color: #409eff;
  color: #fff;
}

.nav-right {
  display: flex;
}
.nav-right .nav-right-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #010117;
  padding: 10px 15px;
  border-radius: 8px;
  margin-right: 10px;
}
.nav-right .nav-right-item .yes-bulk {
  width: 20px;
  height: 20px;
  background: #dcffe4;
  box-shadow: 2px 2px 0px 1px #95e2a6;
}
.nav-right .nav-right-item .no-bulk {
  width: 20px;
  height: 20px;
  background: #e6e6e6;
  box-shadow: 2px 2px 0px 1px #d1d1d1;
}
.nav-right .nav-right-item .info {
  margin-left: 8px;
}

@media screen and (max-width: 600px) {
  .show-room-nav {
    display: block;
  }
  .nav-left {
    display: block;
  }
  .nav-left .nav-left-item {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  .nav-right {
    margin-top: 1rem;
  }
}
</style>
