import request from "@/utils/request";

export function listByParent(parentId) {
  return request({
    url: `/v1/booth/list/${parentId}`,
    method: "get",
  });
}

export function listRoom(parentId) {
  return request({
    url: `/v1/booth/list/room/${parentId}`,
    method: "get",
  });
}