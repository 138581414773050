<template>
  <div class="show-room">
    <ShowRoomNav :searchId="searchId"></ShowRoomNav>
    <div class="show-room-content content">
      <div class="roomCom">
        <div class="roomBox">
          <el-popover
            v-for="item in roomData"
            :key="item.id"
            placement="top-start"
            :title="`当前展位号：` + item.name"
            width="200px"
            trigger="hover"
          >
            展位状态：{{ item.companyId > 0 ? "有企业入驻" : "无企业入驻" }}
            <br />
            订展公司：{{ item.companyName }}
            <div
              class="roomData"
              :style="item.info.style"
              :class="[item.companyId > 0 ? 'pay' : '', 'type1']"
              slot="reference"
            >
              <div class="address"></div>
              <div class="name">{{ item.name }}</div>
              <div
                v-if="item.companyId > 0"
                class="btn"
                @click="handleClick(item.companyId)"
              >
                查看企业
              </div>
              <div v-else class=""></div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowRoomNav from "@/components/Cms/ShowRoomNav.vue";
import { listRoom } from "@/api/cms/booth.js";

export default {
  components: {
    ShowRoomNav,
  },
  data() {
    return {
      roomData: [],
      searchId: -1,
    };
  },
  methods: {
    //跳转页面传入展位号
    handleClick(companyId) {
      if (companyId > 0) {
        window.open(`/cms/company/show/${companyId}`);
      }
    },
    search(id) {
      listRoom(id).then((res) => {
        for (var i = 0; i < res.length; i++) {
          var item = res[i];
          let jsonConfig = JSON.parse(item.jsonConfig);
          item.info = jsonConfig;
        }
        this.roomData = res;
        this.searchId = Number(id);
      });
    },
  },
  created() {
    this.search(this.$route.params.id);
  },
};
</script>

<style scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
.show-room {
  background-image: url("/images/bj.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 60px 0;
  margin-top: 60px;
}

.show-room-content {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  margin-top: 40px;
  height: 150em;
}

.imageBg {
  opacity: 0.1;
}
.roomCom {
  position: relative;
}
.roomBox {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.roomData {
  width: 60px;
  height: 90px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  background-color: #e8e8e8;
  box-shadow: 3px 3px 0px #707070;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 在主轴上均匀分布子元素 */
  align-items: center; /* 在交叉轴上居中对齐子元素 */
}
.roomData.pay {
  background-color: #e5ffeb;
  box-shadow: 3px 3px 0px #95e2a6;
}
.roomData .address {
  font-size: 12px;
  margin: 5px;
  margin-bottom: 0;
}
.roomData .name {
  font-weight: bold;
  font-size: 20px;
  margin: 5px;
}
.roomData .btn {
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px;
  margin: 2px;
  transform: scale(0.7);
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .show-room {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .show-room-nav {
    width: auto;
  }
  .roomCom {
    width: 1200px;
  }
  .show-room-content {
    margin: 0;
    width: auto;
    overflow-x: scroll;
  }
}
</style>
